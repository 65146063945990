import { Loader } from "@medm-ui/loader";
import "./loader.css";
import ReactDOM from "react-dom";

export function UnionLoader(props: any) {
  if (props.subtle == true) {
    return ReactDOM.createPortal(
      <div className="spinner">
        <Loader id={props.id} size={"small"} shade={"light"}></Loader>
      </div>,
      document.body
    );
  } else {
    return ReactDOM.createPortal(
      <>
        <div className="backdrop"></div>
        <div className="loader">
          <Loader id={props.id} size={"large"} shade={"light"}></Loader>
        </div>
      </>,
      document.body
    );
  }
}
