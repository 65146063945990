import { useState } from "react";
import { Button } from "@medm-ui/button";
import { RadioButton } from "@medm-ui/radio-button";
import { RadioButtonItem } from "@medm-ui/radio-button/dist/esm/lib/radio-button.types";
import { useDropzone, FileWithPath } from "react-dropzone";
import { Label } from "@medm-ui/label";
import { FeedbackType, UnionConfig } from "../../models";
import { getUser } from "../../services/user/user.service";
import { sendFeedback } from "../../services/feedback/feedback.service";
import { UnionModal } from "../../components/shared/modal";
import "./feedback.css";
import { useAppSelector } from "redux/hooks";

export function Feedback(props: any) {
  const unionConfig = useAppSelector<UnionConfig>((state) => state.unionConfig.unionConfig);
  const [experience, setexperience] = useState("");
  const [comments, setcomments] = useState("");
  const [valid, setvalid] = useState({
    validExperience: false,
    validComments: false,
  });
  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/jpg": [],
    },
  });

  const attachments: any = [];
  const fileNames: any = [];

  const files = acceptedFiles.map((file: FileWithPath) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  acceptedFiles.map((file: FileWithPath) => {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      attachments.push(event.target.result);
      fileNames.push(file.name);
    };
    reader.readAsDataURL(file);
  });

  function submitFeedback() {
    const viewPort = window.innerWidth + "*" + window.innerHeight;
    const response = getUser();

    const feedback: FeedbackType = {
      userName: response.profile.given_name,
      userFullName: response.profile.name,
      emailAddress: response.profile.email,
      rating: parseInt(experience),
      comment: comments,
      timestamp: new Date(),
      lastLocation: window.location.href,
      userAgent: "userAgent",
      viewport: viewPort,
      attachments: attachments,
      fileName: fileNames,
    };

    sendFeedback(unionConfig, feedback)
      .then(() => {
        alert("Feedback sent!");
        handleClose();
      })
      .catch((error) => {
        alert("Error Occured: " + error?.message);
      });
  }

  function handleClose() {
    props.showFeedbackHandler(false);
  }

  function handleDisabled() {
    return !(valid.validComments && valid.validExperience);
  }

  return (
    <>
      <form action="" onSubmit={submitFeedback}>
        <UnionModal
          id="feedback-modal"
          title="Your Feedback is Valuable"
          size="small"
          actions={[
            {
              buttonId: "submit-feedback",
              buttonLabel: "Submit Feedback",
              buttonType: "Primary",
              disabled: handleDisabled(),
              onButtonClick: submitFeedback,
              buttonSize: "small"
            },
            {
              buttonId: "cancel-feedback",
              buttonLabel: "Cancel",
              buttonType: "Subtle",
              disabled: false,
              onButtonClick: () => {
                props.showFeedbackHandler(false);
              },
              buttonSize: "small"
            },
          ]}
          onClose={handleClose}
          actionsLayout={"start"}
        >
          <div className="feedback-container">
            <Label
              label={"How would you rate your overall experience?"}
              size={"XSmall"}
              weight={"Bold"}
              className="feedbackLabel"
            ></Label>
            <RadioButton
              id="experience-radiobutton"
              disabled={false}
              orientation={"horizontal"}
              readonly={false}
              error={false}
              defaultValue={experience}
              radioButtons={[
                {
                  value: "4",
                  label: "Excellent",
                },
                {
                  value: "3",
                  label: "Good",
                },
                {
                  value: "2",
                  label: "Average",
                },
                {
                  value: "1",
                  label: "Needs Improvement",
                },
              ]}
              selectCallbackFn={(inputvalue: RadioButtonItem) => {
                setexperience(inputvalue.value);
                setvalid((prevValid: any) => {
                  prevValid.validExperience = true;
                  return prevValid;
                });
              }}
            ></RadioButton>
            <div
              className="comment"
              data-test-id="feedback-comment"
              id="feedback-comment"
            >
              <Label
                label={"Comments/Suggestions"}
                size={"XSmall"}
                weight={"Bold"}
                className="feedbackLabel"
              ></Label>
              <textarea
                id="feedback-comment-textarea"
                data-test-id="feedback-comment-textarea"
                placeholder="Enter your comments/suggestions"
                value={comments}
                onChange={(event) => {
                  setcomments(event.target.value);
                  setvalid((prevValid: any) => {
                    if (event.target.value === "")
                      prevValid.validComments = false;
                    else prevValid.validComments = true;
                    return prevValid;
                  });
                }}
                autoFocus
              ></textarea>
            </div>
            <Label
              label={"Screenshot Attachment"}
              size={"XSmall"}
              weight={"Bold"}
              className="feedbackLabel"
            ></Label>
            <div
              className="attachment"
              data-test-id="feedback-attachment"
              id="feedback-attachment"
            >
              <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                <Button
                  buttonId={"feedback-attachment-select-button"}
                  buttonLabel={"Select File..."}
                  buttonType={"Tertiary"}
                  buttonSize={"medium"}
                  disabled={false}
                  onButtonClick={open}
                  className="attachment-button"
                />
                <p>or</p>
                <p>Drop file here to import</p>
              </div>
              <aside>
                <ul>{files}</ul>
              </aside>
            </div>
          </div>
        </UnionModal>
      </form>
    </>
  );
}
