import { Menu, PermissionList, PermissionSet, UnionConfig } from "models";
import { SessionStorageService } from "services/storage-service";
import { PathHelper } from "services/path-helper";
import axios from "axios";
import store from "store";
import { setPermission } from "redux/features/permission";

export async function loadPermission(
  unionConfig: UnionConfig
): Promise<PermissionSet> {
  const permissionData: PermissionSet = {};
  if (unionConfig.manifest) {
    await Promise.allSettled(
      unionConfig.manifest.map(async (product) => {
        try {
          const url = PathHelper.isAbsolutePath(
            product.environment.apiEndpoints.permissions
          )
            ? product.environment.apiEndpoints.permissions
            : (product.environment.apiRootUrl ||
              product.environment.appRootUrl) +
            "/" +
            product.environment.apiEndpoints.permissions;

          const data = await getPermission(url);
          const permissionLists = parsePermissionResponse(product.key, data);
          if (permissionLists.length) {
            permissionData[product.key] = permissionLists;
          }
        } catch (error) {
          permissionData[product.key] = null;
        }
      })
    );
    store.dispatch(setPermission(permissionData));
    return permissionData;
  }
}

function getPermission(url: string) {
  const timeoutRequest = 30000;
  return new Promise((resolve, reject) => {
    axios
      .get<Menu>(url, { timeout: timeoutRequest })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function parsePermissionResponse(
  productKey: string,
  result: any
): PermissionList {
  if (result.items) {
    // Hack
    if (result.items.length > 1 && result.items[0].permissions) {
      const settings: any = SessionStorageService.getItem(
        "multi-tenant-selection",
        "multi-tenant-selection"
      );
      if (settings && settings[productKey]) {
        const match = result.items.find((x: any) =>
          isTenantMatch(settings[productKey], x)
        );
        if (match) {
          result = [...match.permissions, "MULTI-TENANT"];
        } else {
          result = ["MULTI-TENANT"];
        }
      } else {
        result = ["MULTI-TENANT"];
      }
    } else if (result.items.length == 1 && result.items[0].permissions) {
      result = result.items[0].permissions;
    } else {
      result = result.items;
    }
  }
  return result;
}

function isTenantMatch(
  criteria: { [x: string]: any },
  candidate: { [x: string]: any }
): boolean {
  for (const key in criteria) {
    if (candidate[key] !== criteria[key]) {
      return false;
    }
  }
  return true;
}

export async function isRestricted(
  config: UnionConfig,
  productKey: string
): Promise<string> {
  try {
    const apiUrl =
      config.apiUrl +
      "/permissions/application/" +
      productKey +
      "/isUserRestricted";
    return await axios.get(apiUrl).then((response) => {
      return response.headers.isrestricted;
    });
  } catch (error: any) {
    throw new Error(error.message);
  }
}
