import { useNavigate } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import { getPermission } from "services/permission/permission-helper";
import { getRouteInfo } from "services/router-helper";
import { isRestricted } from "services/permission/permission.service";
import { useEffect, useRef, useState } from "react";

export const ProtectedRoute: React.FC<{ children: JSX.Element }> = ({
  children,
}) => {
  const permissionSet = useAppSelector((state) => state.permission.permission);
  const config = useAppSelector((state) => state.unionConfig.unionConfig);
  const routeMap = useAppSelector((state) => state.route.routeMap);
  const [isEnvRestricted, setIsEnvRestricted] = useState(true);
  const navigate = useNavigate();
  const isMounted = useRef(true);
  const pageData = getRouteInfo(window.location.pathname, routeMap);
  const product = config?.manifest.find((x) => x.key === pageData.productKey);

  useEffect(() => {
    const isValid = getPermission(pageData, permissionSet);
    if (!isValid) {
      navigate("/");
    }

    if (product?.environment.isRestricted) {
      fetchIsRestricted();
    }
    return () => {
      isMounted.current = false;
    }

    async function fetchIsRestricted() {
      try {
        const response = await isRestricted(config, pageData.productKey);
        if (isMounted) {
          if (response !== "false") {
            const state = {
              title: pageData.productName,
              key: pageData.productKey,
              icon: product.icon,
            };
            navigate("/unauthorise", { state });
          } else {
            setIsEnvRestricted(false)
          }
        }
      } catch (err) {
        setIsEnvRestricted(false)
        console.error(err);
      }
    }
  }, [pageData]);

  if (product?.environment.isRestricted) {
    if (isEnvRestricted === false) {
      return children;
    }
  } else {
    return children;
  }
}
