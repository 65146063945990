import { ButtonProps } from "@medm-ui/button";
import { RadioButton } from "@medm-ui/radio-button";
import { RadioButtonItem } from "@medm-ui/radio-button/dist/esm/lib/radio-button.types";
import { UnionModal } from "../../components/shared/modal";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";
import "./environment.css";

export function Environment(props: any) {
  const [selection, setselection] = useState({});
  const [radioData, setRadioData] = useState([]);
  const unionConfig = useAppSelector((state) => state.unionConfig.unionConfig);

  const navigate = useNavigate();

  useEffect(() => {
    setRadioButtonData(unionConfig.manifest);
  }, []);

  function setRadioButtonData(data: any) {
    if (data) {
      const mm: any = [];
      data.forEach((product: any) => {
        const RadioButton = {
          name: product.name,
          key: product.key,
          environmentSelect: "",
          Item: [] as RadioButtonItem[],
        };
        Object.keys(product.environments).forEach((item: any) => {
          const t = {
            value: item,
            label: product.environments[item].name,
          };
          RadioButton.Item.push(t);
          if (product.environments[item].name === product.environment.name) {
            RadioButton.environmentSelect = item;
          }
        });
        mm.push(RadioButton);
      });
      setRadioData(mm);
    }
  }

  function handleClose() {
    props.showModalHandler(false);
  }

  const links = [
    {
      displayName: "Configure SandBox",
      onClick: () => {
        navigate("/sandbox");
        props.showModalHandler(false);
      },
    },
  ];

  const act = [
    {
      buttonId: "Save",
      buttonLabel: "Save",
      buttonType: "Primary",
      disabled: false,
      onButtonClick: () => {
        if (selection) {
          let prevSelection = JSON.parse(localStorage.getItem("environments"));
          if (prevSelection) {
            prevSelection = { ...prevSelection, ...selection };
            window.localStorage.setItem(
              "environments",
              JSON.stringify(prevSelection)
            );
          } else {
            window.localStorage.setItem(
              "environments",
              JSON.stringify(selection)
            );
          }
        }
        window.location.reload();
      },
    },
    {
      buttonId: "Cancel",
      buttonLabel: "Cancel",
      buttonType: "Subtle",
      disabled: false,
      onButtonClick: () => {
        props.showModalHandler(false);
      },
    },
  ] as ButtonProps[];

  return (
    <div className="modal-container">
      <UnionModal
        id={props.id}
        title="Environments Selection"
        size="small"
        actions={act}
        onClose={handleClose}
        links={links}
        actionsLayout={"end"}
        actionBarStyles="environment-action-bar"
      >
        <div className="modal-container">
          {radioData.map((d: any) => {
            return (
              <div key={d.key}>
                <span className="lead">{d.name}</span>
                <ul className="list-group">
                  <li className="list-item">
                    <RadioButton
                      id={d.key}
                      disabled={false}
                      orientation={"vertical"}
                      readonly={false}
                      error={false}
                      defaultValue={d.environmentSelect}
                      radioButtons={d.Item}
                      selectCallbackFn={(inputvalue: any) => {
                        const a: any = [...radioData];
                        a.forEach((x: any, index: number) => {
                          if (x.key == d.key) {
                            a[index].environmentSelect = inputvalue.value;
                          }
                        });
                        setRadioData(a);
                        setselection((selection: any) => {
                          selection[d.key] = inputvalue.value;
                          return selection;
                        });
                      }}
                      className="radio-button"
                    ></RadioButton>
                  </li>
                </ul>
              </div>
            );
          })}
        </div>
      </UnionModal>
    </div>
  );
}
