import { UnionConfig } from "models/union-config";
import axios from "axios";
import { AppVersionInfo } from "models/version-data/app-version-info";
import store from "store";
import { setVersions } from "redux/features/versions";

export async function getVersions(config: UnionConfig) {
  const endpoints = { ui: `${window.location.origin}/assets/app.json`, api: `${config.apiUrl}/health` };
  let uiVersion;
  let apiVersion;
  const results: AppVersionInfo[] = [];

  for (const product of config.manifest) {
    let uiVer;
    let apiVer;
    await Promise.allSettled([
      getVersion(product.environment.apiEndpoints.version, "ui"),
      getVersion(product.environment.apiEndpoints.version, "api"),
    ]).then((results) => {
      results.forEach((result, index) => {
        switch (index) {
          case 0: {
            if (result.status === "fulfilled") {
              uiVer = result.value;
            }
            break;
          }
          case 1: {
            if (result.status === "fulfilled") {
              apiVer = result.value;
            }
            break;
          }
        }
      });
    });
    results.push({
      productKey: product.name,
      productName: product.name,
      ui: uiVer,
      api: apiVer,
    });
  }

  try {
    uiVersion = await getVersion(endpoints, "ui");
    apiVersion = await getVersion(endpoints, "api");
  } catch (error: any) {
    console.error(error.response);
    throw error.response;
  }
  store.dispatch(setVersions({ ui: uiVersion, api: apiVersion, apps: results }))
  return { ui: uiVersion, api: apiVersion, apps: results };
}

async function getVersion(
  endpoints: any,
  source: string,
  parser?: (response: any) => string
): Promise<string> {
  try {
    const timeoutRequest = 30000;
    const url = endpoints ? endpoints[source] : undefined;
    if (url) {
      return await axios
        .get(url, { timeout: timeoutRequest })
        .then((response: any) => {
          if (response.data.data) {
            return response.data.data.items.map((response: any) => {
              if (parser) {
                return parser(response);
              } else {
                return response.info["version"] || response[source + "Version"];
              }
            })[0];
          } else if (
            response.data.version ||
            response.data.apiVersion ||
            response.data.uiVersion
          ) {
            return response.data.version
              ? response.data.version
              : response.data.apiVersion
                ? response.data.apiVersion
                : response.data.uiVersion
                  ? response.data.uiVersion
                  : new Promise<string>((resolve) => {
                    resolve("N/A");
                  });
          } else {
            return new Promise<string>((resolve) => {
              resolve("N/A");
            });
          }
        });
    } else {
      return new Promise<string>((resolve) => {
        resolve("N/A");
      });
    }
  } catch (error) {
    return new Promise<string>((resolve) => {
      resolve("N/A");
    });
  }
}
