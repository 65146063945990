import { useEffect } from "react";
import { useAppSelector } from "../../redux/hooks";
import { logout } from "services/logout/logout.service";

export function Logout(props: any) {
  const unionConfig = useAppSelector((state) => state.unionConfig.unionConfig);

  useEffect(() => {
    logout(unionConfig);
  });

  return (<></>);
}
