import { Manifest, Product, UnionConfig } from "models";
import { setunionConfig } from "redux/features/unionconfig";
import store from "store";
import { interceptor } from "services/interceptor";
import axios, { AxiosResponse } from 'axios';

export async function UnionConfigLoader() { 
  let unionConfig = new UnionConfig();

  await fetch(
    window.location.origin +
      "/assets/union-config/" +
      window.location.hostname +
      ".json"
  ).then((response) => {
    response.json().then(async (configValue: UnionConfig) => {
      unionConfig = configValue;

      interceptor(unionConfig);
      
      let manifestUrl;
      if(configValue.manifestFromApi) {
        manifestUrl = unionConfig.apiUrl + "/manifest"
      } else {
        manifestUrl = window.location.origin +
        "/assets/union-config/manifest/" +
        unionConfig.manifest;
      }      

      axios.get(manifestUrl).then((value : AxiosResponse<Manifest, any>) => {
        unionConfig.manifest = value.data;

        if (unionConfig.sandboxEnabled && localStorage.manifest) {
          unionConfig.manifest = JSON.parse(localStorage.manifest);
        }

        const environments = JSON.parse(
          localStorage.getItem("environments") || "{}"
        );
        
        unionConfig.manifest.forEach((x: Product) => {
          if (!x.environment) {
            if (environments[x.key] && x.environments[environments[x.key]]) {
              x.environment = x.environments[environments[x.key]];
            } else if (
              unionConfig.defaultEnvironment &&
              x.environments[unionConfig.defaultEnvironment]
            ) {
              x.environment = x.environments[unionConfig.defaultEnvironment];
            } else {
              x.environment =
                x.environments[Object.keys(x.environments).reverse()[0]];
            }
          } else {
            if (
              environments[x.key] &&
              x.environment.name.toLocaleLowerCase() !== environments[x.key]
            ) {
              x.environment = x.environments[environments[x.key]];
            }
          }
        });
        
        if (unionConfig.sandboxEnabled && localStorage.manifest) {
          localStorage.setItem(
            "manifest",
            JSON.stringify(unionConfig.manifest)
          );
        }

        store.dispatch(setunionConfig(unionConfig));
      });
    });
  });
}
