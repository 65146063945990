import { UnionConfig } from "models/union-config";
import axios from "axios";
import { LastVisitedPage } from "models/last-visited-page/last-visited-page";

export async function insertLastVisitedPage(
  config: UnionConfig,
  data: LastVisitedPage
) {
  const LVPEndpoint = `${config.apiUrl}/UserActivity/LastVisitedPages`;
  try {
    const response = await axios.post(LVPEndpoint, data);
    return response.data;
  } catch (error: any) {
    console.error(error.response);
    throw error.response;
  }
}

export async function getLastVisitedPages(
  config: UnionConfig
): Promise<Array<LastVisitedPage>> {
  const LVPEndpoint = `${config.apiUrl}/UserActivity/LastVisitedPages`;
  try {
    const response = await axios.get(LVPEndpoint);
    return response.data;
  } catch (error: any) {
    console.error(error.response);
    throw error.response;
  }
}
