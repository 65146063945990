import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Header } from "@medm-ui/header";
import moment from "moment";
import { getUser } from "services/user/user.service";
import { Button } from "@medm-ui/button";
import { useAppSelector } from "redux/hooks";
import "./header.css";

export function UnionHeader(props: any) {
  const [state, setState] = useState({
    openModal: false,
    openFeedback: false,
    openAbout: false,
    userInfo: {} as any,
  });
  const [isSandboxMode, setIsSandboxMode] = useState(false);
  const navigate = useNavigate();
  const unionConfig = useAppSelector((state) => state.unionConfig.unionConfig);
  const product = unionConfig.manifest?.find((item) => item.key === props.pathname.split("/")[1]);

  const handleLogout = () => {
    navigate("/logout");
  };

  useEffect(() => {
    const response = getUser();
    const data = {
      name: response.profile.name,
      loginTime: response.profile.iat,
    };
    setState((prev) => ({ ...prev, userInfo: data }));
  }, []);

  useEffect(() => {
    localStorage.getItem("manifest")
      ? setIsSandboxMode(true)
      : setIsSandboxMode(false);
  });

  const style = {
    height: "60px",
    paddingLeft: "12px",
  };

  const centerItem = (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "90%",
      }}
    >
      <span>
        {isSandboxMode && (
          <Button
            buttonId="sandbox-header-button"
            buttonLabel="SANDBOX"
            buttonType="Secondary"
            widthSize="79px"
            heightSize="24px"
            onButtonClick={() => {
              navigate("/sandbox");
            }}
            className="sandbox-button"
            buttonSize={"small"}
          />
        )}
      </span>
    </div>
  );

  const rightIcons = () => {
    const rightIcons = [];
    if (product?.environment.supportUrl) {
      rightIcons.push(
        {
          label: "Support",
          icon: "fa-info",
          onIconClick: () => window.open(product.environment.supportUrl, "_blank").focus(),
          id: "support-selector",
          size: "lg",
          isFontAwesome: true,
        });
    }
    if (unionConfig.sandboxEnabled) {
      rightIcons.push(
        {
          label: "Environment",
          icon: "fa-globe",
          onIconClick: () => props.openModal(true),
          id: "environment-selector",
          size: "lg",
          isFontAwesome: true,
        });
    }
    return rightIcons;
  };

  return (
    <Header
      onLogoClick={() => {
        navigate("/");
      }}
      rightIcons={rightIcons()}
      userInfo={{
        name: state.userInfo.name,
        lastLoginTime: moment(state.userInfo.loginTime * 1000).format(
          "DD/MM/YYYY HH:mm:ss"
        ),
      }}
      userProfileMenuItems={[
        {
          label: "Feedback",
          icon: "fas fa-files",
          onMenuClick: () => {
            props.openFeedback(true);
            setState((prev) => ({
              ...prev,
              openFeedback: !state.openFeedback,
            }));
          },
          id: "user-profile-feedback",
          isFontAwesome: true,
        },
        {
          label: "About",
          icon: "fas fa-files",
          onMenuClick: () => {
            props.openAbout(true);
            setState((prev) => ({ ...prev, openAbout: !state.openAbout }));
          },
          id: "user-profile-about",
          isFontAwesome: true,
        },
      ]}
      logOutButtonLabel={"Logout"}
      onLogoutClick={handleLogout}
      userInfoPopupId={"user-info-popup"}
      style={style}
      centerItem={centerItem}
    ></Header>
  );
}
