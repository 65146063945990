import { createContext, useEffect, useState } from "react";
import { SideNav } from "./side-nav";
import { UnionBreadcrumb } from "./breadcrumb";
import { Environment } from "components/environment";
import { Feedback } from "components/feedback";
import { UnionFooter } from "./footer";
import { UnionHeader } from "./header";
import { About } from "components/about";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { getBreadcrumbData, getRouteByLocalPath } from "services/router-helper";
import { useAppSelector } from "redux/hooks";
import "./layout.css";
import { Product } from "models";
import logoutChannel from "services/logout/logoutChannel";

export const ActivePathContext = createContext(null);
export function Layout(props: any) {
  const routeMap = useAppSelector((state) => state.route.routeMap);
  const unionConfig = useAppSelector((state) => state.unionConfig.unionConfig);
  const [showModal, setShowModal] = useState(false);
  const [showFeedback, setshowFeedback] = useState(false);
  const [showAbout, setshowAbout] = useState(false);
  const [breadcrumbData, setBreadcrumbData] = useState([]);
  const { pathname, state } = useLocation();
  const navigate = useNavigate();
  const [product, setProduct] = useState({} as Product);
  const [isPopOutWindow, setIsPopOutWindow] = useState(false);

  const modalHandler = (x: any) => {
    setShowModal(x);
  };
  const feedbackHandler = (x: any) => {
    setshowFeedback(x);
  };
  const aboutHandler = (x: any) => {
    setshowAbout(x);
  };
  const location = useLocation();
  useEffect(() => {
    const route = getRouteByLocalPath(pathname, routeMap);
    setIsPopOutWindow((window.opener && window.opener !== window && window.opener.location.origin === window.location.origin));
    if (route) {
      const prod = unionConfig.manifest.find(
        (x: { key: unknown }) => x.key === route.productKey
      );
      setProduct(prod);
    } else {
      setProduct({} as Product);
    }
    if (pathname.length === 1) {
      //Set pageTitle when on Union Home
      document.title = "Union";
      setBreadcrumbData([
        {
          key: "home",
          label: "Home",
        },
      ]);
    } else if (pathname.includes("lastVisited")) {
      setBreadcrumbData([
        {
          key: "home",
          label: "Home",
        },
        {
          key: "lastvisited",
          label: `${location.state.key.charAt(0).toUpperCase() +
            location.state.key.substring(1)
            } Last Visited`,
        },
      ]);
    } else if (pathname.includes("sandbox")) {
      setBreadcrumbData([
        {
          key: "sandbox",
          label: "Configure Sandbox",
        },
      ]);
    } else {
      let breadcrumb: any = [];
      if (state) {
        breadcrumb.push({
          key: state.key,
          label: state.title,
        });
      } else {
        breadcrumb = getBreadcrumbData(pathname, routeMap);
      }
      setBreadcrumbData(breadcrumb);
    }
  }, [pathname]);

  useEffect(() => {
    logoutChannel.addEventListener('message', (event) => {
      if (event.origin === window.location.origin && event.data === "logout") {
        navigate('/logout');
      }
    });
  }, []);

  return (
    <>
      {!isPopOutWindow && (
        <><SideNav /><div className="wrapper">
          <UnionHeader
            className="header"
            openModal={modalHandler}
            openFeedback={feedbackHandler}
            openAbout={aboutHandler}
            isSandboxMode={props.isSandboxMode}
            pathname={pathname} />
          <div className="divider"></div>
          {(product?.isUnionSubHeader === undefined ||
            product?.isUnionSubHeader === true) && (
              <>
                <div className="breadcrumb">
                  <UnionBreadcrumb breadcrumb={breadcrumbData} />
                </div>
                <div className="divider"></div>
              </>

            )}
          <div className="container">
            <Outlet />
          </div>
          <div className="footer">
            <div className="divider"></div>
            <UnionFooter />
          </div>
        </div></>)}
      {isPopOutWindow &&
        <div className="popout-container">
          <Outlet />
        </div>}
      {showModal && (
        <Environment showModalHandler={modalHandler} id="environment-modal" />
      )}
      {showFeedback && (
        <Feedback showFeedbackHandler={feedbackHandler} id="feedback-modal" />
      )}
      {showAbout && (
        <About showAboutHandler={aboutHandler} id="about-modal" />
      )}
    </>
  );
}
