import { createSlice } from "@reduxjs/toolkit";

export const versionsSlice = createSlice({
  name: "versions",
  initialState: {
    versions: null,
  },
  reducers: {
    setVersions: (state, action) => {
      return {
        ...state,
        versions: action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setVersions } = versionsSlice.actions;

export default versionsSlice.reducer;