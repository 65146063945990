import { createSlice } from "@reduxjs/toolkit";
import { UnionConfig } from "models/union-config";

export const unionConfigSlice = createSlice({
  name: "unionConfig",
  initialState: {
    unionConfig: {} as UnionConfig,
  },
  reducers: {
    setunionConfig: (state, action) => {
      state.unionConfig = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setunionConfig } = unionConfigSlice.actions;

export default unionConfigSlice.reducer;
