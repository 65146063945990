import CryptoES from 'crypto-es';

export class SessionStorageService {

  public static getItem<T>(name: string, key: string): T {
    const item = sessionStorage.getItem(name);
    if (item) {
      return SessionStorageService.decryptData(item, name, key) as T;
    }
    return JSON.parse('{}') as T;
  }

  public static setItem(name: string, object: string, key: string): void {
    const encrypted = CryptoES.AES.encrypt(object, key);
    sessionStorage.setItem(name, encrypted.toString());
  }

  private static decryptData = (object: string, name: string, key: string): any => {
    let jsonObj = '';
    try {
      jsonObj = CryptoES.AES.decrypt(object, key).toString(CryptoES.enc.Utf8);
    }
    catch (ex) {
      SessionStorageService.setItem(name, object, key);
      const encryptedItem = sessionStorage.getItem(name) as string;
      jsonObj = CryptoES.AES.decrypt(encryptedItem, key).toString(CryptoES.enc.Utf8);
    }
    if (!jsonObj) {
      jsonObj = '{}';
    }
    return JSON.parse(jsonObj);
  }
}
