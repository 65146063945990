import { getAuthSettings } from "auth-settings";
import { User as OidcUser, UserManager } from "oidc-client-ts";
import { SessionStorageService } from "services/storage-service";
import { setUserAuth } from "redux/features/auth";
import store from "store";
import CryptoES from "crypto-es";

export function getUser(): OidcUser {
  const userInfo: OidcUser = SessionStorageService.getItem(
    "user-info",
    "user-info"
  );
  return userInfo;
}

export function updateLastActiveProduct() {
  let activeProduct = JSON.parse(localStorage.getItem('activeProduct'));
  let lastActiveProduct = JSON.parse(localStorage.getItem('lastActiveProduct'));
  activeProduct = (activeProduct === null) ? [] : activeProduct;
  lastActiveProduct = (lastActiveProduct === null) ? [] : lastActiveProduct;
  activeProduct.filter(((data: any) => {
    if (lastActiveProduct?.includes(data)) {
      return
    }
    else lastActiveProduct.push(data);
  }));
  localStorage.setItem('lastActiveProduct', JSON.stringify(lastActiveProduct));
  localStorage.removeItem('activeProduct');
}

export function loadUser() {
  const oidcUser: OidcUser = getUser();
  HandleUpdate(oidcUser);
  setInterval(() => updateUser(), 1200000);
}

export function updateUser() {
  getAuthSettings().then(settings => {
    const userManager = new UserManager(settings);

    userManager
      .signinSilent()
      .then((oidcUser) => {
        SessionStorageService.setItem(
          "user-info",
          JSON.stringify(oidcUser),
          "user-info"
        );
        SessionStorageService.setItem(
          "accessToken",
          JSON.stringify(oidcUser.access_token),
          "union-access-token"
        );
        Object.keys(sessionStorage)
          .filter((x) => x.startsWith("oidc."))
          .forEach((x) => sessionStorage.removeItem(x));
        HandleUpdate(oidcUser);
      })
      .catch((error) => {
        console.error(error);
      });
  })

}

function HandleUpdate(oidcUser: OidcUser) {
  const _authToken = {
    value: oidcUser.access_token,
    type: oidcUser.token_type,
    expires: oidcUser.expires_at,
  };
  const oidUser = JSON.stringify(oidcUser);
  const oidUserToken = JSON.stringify(oidcUser.access_token);

  store.dispatch(
    setUserAuth({
      accessToken: CryptoES.AES.encrypt(oidUserToken, "union-access-token").toString(),
      "user-info": CryptoES.AES.encrypt(oidUser, "user-info").toString(),
      authToken: _authToken,
    })
  );
}
